<template>
  <div id="linkTransform">
    <vue-btn-return></vue-btn-return>
    <v-snackbar v-model="isSuccess" :timeout="timer" top right color="success">
      复制成功
      <!-- <v-btn text @click="isSuccess = false"> Close </v-btn> -->
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <div class="link__text mt-5" align="center" justify="center">
      <h2 class="mb-8">链接提取工具</h2>
    </div>
    <div class="custom-options">
      <v-row>
        <v-col>
          <v-checkbox
            v-model="checkbox"
            label="斜线"
            @change="extractLink()"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-model="isTitle" label="标题"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-model="isLink" label="链接"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-model="isId" label="用户/小组ID"></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <div class="box" align="center" justify="center">
      <div class="box-left">
        <div
          class="ori-content"
          contenteditable="true"
          @input="extractLink()"
          ref="oriContent"
        ></div>
      </div>
      <div class="box-center">
        <!-- <div class="custom-options">
          <v-checkbox
            v-model="isTitle"
            label="输出标题"
          ></v-checkbox>
          <v-checkbox
            v-model="checkbox"
            label="末尾斜线"
            @change="extractLink()"
          ></v-checkbox>
        </div> -->
        <v-btn class="mb-4" color="secondary" outlined depressed @click="clear()"
          ><v-icon left dark>mdi-arrow-left</v-icon>清空</v-btn
        >
        <p class="mb-4">=></p>
        <v-btn color="primary" depressed outlined @click="copyLink()"
          >复制<v-icon right dark>mdi-arrow-right</v-icon></v-btn
        >
      </div>
      <div class="box-right">
        <v-textarea
          outlined
          name="aft"
          label="转换后的内容"
          height="60vh"
          hide-details="false"
          no-resize
          ref="transformLink"
          id="transformLink"
          :value="transformLink"
          contenteditable="true"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import BtnReturn from "../components/BtnReturn";
export default {
  name: "LinkTransform",
  data: () => ({
    isSuccess: false,
    timer: 3000,
    aftArr: [],
    checkbox: true,
    isLink: true,
    isTitle: false,
    isId: false,
  }),
  components: {
    "vue-btn-return": BtnReturn,
  },
  methods: {
    clear() {
      this.$refs.oriContent.innerHTML = "";
      this.aftArr = [];
    },
    copyLink() {
      if (this.aftArr.length) {
        let links = this.$refs.transformLink;
        links.$el.querySelector("textarea").select();
        document.execCommand("copy");
        this.isSuccess = true;
      }
    },
    getId(href) {
      let arr = href
        .replace(
          /^\/+|\/+$|\/(&|\?).*|\/(\s+)?"\w+\s\w+"$|\/+\s+$|\?\w+\=\d+$/g,
          ""
        )
        .split("/");
      return arr[arr.length - 1];
    },
    extractLink(e) {
      let trs = [...this.$refs.oriContent.querySelectorAll("tbody > tr")];
      let innerText = this.$refs.oriContent.innerText
      if (innerText[innerText.length-1] === '\n') {
        innerText = innerText.slice(0, -1)
      }

      if (!trs.length) {
        trs = [this.$refs.oriContent];
      }
      this.aftArr = [];
      if (trs.length > 0) {
        trs.map((item) => {
          let aEl = item.querySelectorAll("a");
          let href = ''
          if (!aEl || aEl.length === 0) {
            href = item.innerText
          } else {
            aEl = aEl[aEl.length - 1]
            href = aEl.getAttribute("href");
          }
          if (this.checkbox) {
            href = href.replace(
              /^\/+|\/+$|\/(&|\?).*|\/(\s+)?"\w+\s\w+"$|\/+\s+$|\?\w+\=\d+$/g,
              ""
            );
          }
          this.aftArr.push({
            title: aEl.innerText ? aEl.innerText : '',
            href: href,
            id: this.getId(href),
          });
        });
      }
      // this.$refs.transformLink.value = this.aftArr.join("\n")
    },
  },
  computed: {
    transformLink() {
      const titles = this.aftArr.map((item) => {
        // if (this.isTitle) {
        //   return `${item.title}\t${item.href}`;
        // } else {
        //   return item.href;
        // }
        return `${this.isTitle ? item.title + "\t" : ""}${
          this.isLink ? item.href + "\t" : ""
        }${this.isId ? item.id : ""}`;
      });
      return titles.join("\n");
      // return this.aftArr.join("\n");
      // let arr = this.links.split("\n");
      // let newArr = arr.map((val) =>
      //   val.replace(/^\/+|\/+$|\/(&|\?).*|\/(\s+)?"\w+\s\w+"$|\/+\s+$/g, "")
      // );
      // return newArr.join("\n");
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
}

.box-center {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box-left,
.box-right {
  width: 50%;
}

textarea {
  height: 60vh;
}

.ori-content {
  padding: 10px;
  text-align: left;
  height: 60vh;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 4px;
  overflow: scroll;
}

.ori-content table,
td {
  border: none !important;
  border-collapse: none;
}

.ori-content:focus {
  outline: none;
}
</style>